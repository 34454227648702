<template>
    <v-container fluid>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-btn icon
                           light
                           @click="$router.go(-1)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h1 v-if="mailTemplateId > 0">{{mailTemplate.name}}</h1>
                    <h1 v-else>{{ $t('common_create') }}</h1>
                </v-col>

                <v-col align="end">
                    <v-btn color="success"
                           elevation="2"
                           large
                           @click="saveMailTemplate()">
                        {{ $t('common_save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-card style="background-color: #f3f2e8;">
            <v-toolbar dense
                       dark
                       max-height="50"
                       color="grey">
                <v-toolbar-items>
                    <div class="text-center ml-5 mt-3" v-if="mailTemplateId > 0">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs"
                                        v-on="on"
                                        dense>
                                    mdi-dots-vertical
                                </v-icon>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn color="error" tile @click="deleteDialog = !deleteDialog">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                            {{ $t('common_delete') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="mt-3">
                <v-text-field v-model="mailTemplate.name" label="name"></v-text-field>
                <v-text-field v-model="mailTemplate.bccEmailAddresses" label="bccEmailAddresses"></v-text-field>
                <v-text-field v-model="mailTemplate.subject" label="subject"></v-text-field>
                <editor v-model="mailTemplate.body" apiKey="t3901zhqvavjjxl06n4kf7ieydmqyu3le1xglrtn6ohs7td2" />
                <v-checkbox v-model="mailTemplate.isActive"
                            label="isActive">
                </v-checkbox>
            </v-card-text>
            <div style="flex: 1 1 auto;"></div>
            <v-container fluid v-if="mailTemplateId > 0">
                <v-toolbar dark
                           color="grey">
                    <v-toolbar-title>Koppla e-postkonton</v-toolbar-title>
                    <v-autocomplete v-model="accountsSelected"
                                    :items="accounts"
                                    v-on:change='connectAccount'
                                    class="mx-4"
                                    flat
                                    hide-no-data
                                    hide-details
                                    label="S�k e-postkonton"
                                    solo-inverted></v-autocomplete>
                    <v-btn color="secondary" @click="removeConnectedEmailAccount()">Koppla bort</v-btn>
                    <!--
                                        :loading="accountsAutocompleteLoading"
                                            :search-input.sync="searchAccounts"
                -->
                </v-toolbar>
            </v-container>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="deleteDialog"
                      max-width="320">
                <v-card>
                    <v-card-title class="text-h5">
                        {{ $t('admin_dashboard_deletionapproval') }} {{mailTemplate.name}}?
                    </v-card-title>
                    <!--<v-card-text></v-card-text>-->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error"
                               @click="deleteMailTemplate()">
                            <v-icon right>
                                mdi-delete
                            </v-icon>
                            {{ $t('common_delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="deleteDialog = !deleteDialog">
                            {{ $t('common_cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>


        <!--snackbar-->
        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar">
                {{ snackbarText }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="pink"
                           text
                           v-bind="attrs"
                           @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
    import mailTemplateService from '@/services/mailTemplateService';
    import router from '@/router';
    import emailAccountService from '@/services/emailAccountService';
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: 'EditMailTemplate',
        props: ["mailTemplateId"],
        components: {
            'editor': Editor
        },
        data: () => ({
            snackbar: false,
            snackbarText: '',

            //account select
            accountsSelected: null,
            accountsAutocompleteLoading: null,
            accounts: null,
            searchAccounts: null,

            accountsHolder: null,
            //account select end

            deleteDialog: false,

            mailTemplate: {
                name: '',
                bccEmailAddresses: '',
                subject: '',
                body: '',
                isActive: false,
                delayBeforeSend: 0,
                delayPeriond: 0,
                attachedDownloadId: 0,
                emailAccountId: 0,
                delayPeroid: 0
            }
        }),
        methods: { 
            removeConnectedEmailAccount() {
                if (this.accountsSelected == null) {
                    return;
                }
                mailTemplateService.unconnectEmailAccount({ accountName: this.accountsSelected, mailTemplateId: this.mailTemplateId })
                    .then((data) => {
                        this.accountsSelected = null;
                        this.snackbarText = "E-postkonto kopplades bort."
                        this.snackbar = true;
                    });
            },
            connectAccount(e) {
                mailTemplateService.connectEmailAccount({ accountName: this.accountsSelected, mailTemplateId: this.mailTemplateId })
                    .then((data) => {
                        this.snackbarText = "E-postkonto kopplades till mallen."
                        this.snackbar = true;
                    });

            },
            emitCloseDialog() {
                router.go(-1)
            },
            deleteMailTemplate() {
                mailTemplateService.deleteMailTemplate(this.mailTemplateId)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveMailTemplate() {
                if (this.mailTemplateId > 0) {
                    mailTemplateService.updateMailTemplate({
                        id: this.mailTemplateId,
                        name: this.mailTemplate.name,
                        bccEmailAddresses: this.mailTemplate.bccEmailAddresses,
                        subject: this.mailTemplate.subject,
                        body: this.mailTemplate.body,
                        isActive: this.mailTemplate.isActive,
                        delayBeforeSend: this.mailTemplate.delayBeforeSend,
                        attachedDownloadId: this.mailTemplate.attachedDownloadId,
                        emailAccountId: this.mailTemplate.emailAccountId,
                        delayPeriodId: this.mailTemplate.delayPeriodId
                    })
                        .then(data => {
                            router.go(-1)
                        });
                }
                else {
                    mailTemplateService.create({
                        name: this.mailTemplate.name,
                        bccEmailAddresses: this.mailTemplate.bccEmailAddresses,
                        subject: this.mailTemplate.subject,
                        body: this.mailTemplate.body,
                        isActive: this.mailTemplate.isActive,
                        delayBeforeSend: this.mailTemplate.delayBeforeSend,
                        attachedDownloadId: this.mailTemplate.attachedDownloadId,
                        emailAccountId: this.mailTemplate.emailAccountId,
                        delayPeriodId: this.mailTemplate.delayPeriodId
                    })
                        .then(data => {
                            router.go(-1)
                        });
                }
            },
            fillView(data) {
                if (data) {
                    this.mailTemplate = data.mailTemplate;
                    if (data.mailTemplate.emailAccountId > 0) {
                        this.accountsSelected = data.selectedEmailAccountDisplayName;
                    }
                }
            },
        },
        mounted() {
            if (this.mailTemplateId > 0) {
                mailTemplateService.getMailTemplate(this.mailTemplateId)
                    .then(data => {
                        this.fillView(data);
                    });
            }
            emailAccountService.getAllFreeEmailAccounts()
                .then((data) => {
                    this.accounts = data.map(function (item) {
                        return item["displayName"];
                    });
                })
                .catch(function (error) {
                    alert(error);
                });
        },
    }
</script>